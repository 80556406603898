import React from 'react';
import Image, { StaticImageData } from 'next/image';
import { Box, Typography } from '@mui/material';
import { Icon24InfoBgcFilled } from '@care/react-icons';
import { Rating } from '@care/react-component-lib';

import { SxClassProps } from '@/types';

const classes: SxClassProps = {
  slide: {
    width: {
      xs: '327px',
      sm: '296px',
      lg: '409px',
    },
    height: {
      xs: '358px',
      sm: '380px',
      lg: '394px',
    },
    backgroundColor: 'transparent',
    boxSizing: 'content-box',
    border: `initial`,
    padding: '4px',
    margin: '0 4px',
  },
  imageSummaryContainer: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '16px 16px 0 0 ',
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.15)',
  },
  imageContainer: {
    width: '100%',
    height: {
      xs: '202px',
      lg: '228px',
    },
    overflow: 'hidden',
  },
  providerSummary: (theme) => ({
    backgroundColor: theme.palette.care?.white,
    borderRadius: '8px',

    padding: {
      xs: '8px 11px',
      lg: '8px 18px',
    },
    position: 'absolute',
    bottom: '16px',
    right: '16px',
  }),
  providerName: {
    fontSize: {
      xs: '16px',
      lg: '21px',
    },
  },
  providerLocation: {
    fontSize: '14px',
    color: '#505D6D',
  },
  providerRating: {
    marginTop: '0',
    display: 'flex',
    '& .MuiRating-root': {
      gap: '3px',
    },
    color: '#505D6D',
  },
  reviewWrapper: (theme) => ({
    backgroundColor: theme.palette.care?.white,
    padding: 2,
    overflow: 'hidden',
    borderRadius: '0 0 16px 16px',
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.15)',
  }),
  review: {
    fontSize: '16px',
    lineHeight: '22px',
    marginBottom: 2,
  },
};

// Types & Interfaces
interface ITestimonialSlideProps {
  image: StaticImageData;
  name: string;
  review: string;
  reviewer: string;
  rating: number;
  numberOfReviews: number;
  location: string;
}

function TestimonialSlide({
  name,
  image,
  review,
  reviewer,
  rating,
  numberOfReviews,
  location,
}: ITestimonialSlideProps) {
  return (
    <Box sx={classes.slide}>
      <Box sx={classes.imageSummaryContainer}>
        <Box sx={classes.imageContainer}>
          <Image
            alt={name}
            src={image}
            layout="fill"
            objectFit="cover"
            objectPosition="50% 50%"
            unoptimized
          />
        </Box>
        <Box sx={classes.providerSummary}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h2" sx={classes.providerName}>
              {name}
            </Typography>
            <Icon24InfoBgcFilled size={24} />
          </Box>

          <Typography variant="body2" sx={classes.providerLocation}>
            {location}
          </Typography>

          <Box sx={classes.providerRating}>
            <Rating readOnly size="medium" value={rating} />
            <Typography variant="info1">({numberOfReviews})</Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={classes.reviewWrapper}>
        <Typography variant="body1" sx={classes.review}>
          &quot;{review}&quot;
        </Typography>
        <Typography variant="h5">{reviewer}</Typography>
      </Box>
    </Box>
  );
}

export default TestimonialSlide;
