import { useState, Dispatch, SetStateAction } from 'react';
import Slider from 'react-slick';
import { Box, Typography, Button } from '@mui/material';

import { WithLazyLoadProps } from '@/utilities/withLazyLoad';
import useResponsive from '@/components/hooks/useResponsive';
import TestimonialsCarousel from '@/components/shared/TestimonialsCarousel';
import { REBRANDING_TESTIMONIAL_ITEMS } from '@/components/shared/TestimonialsCarousel/UserTestimonials';
import { PhotoIcon, BackgroundCheckIcon, MessageHeartIcon } from '@/components/svg/Rebranding';
import { UNKNOWN_VERTICAL, VERTICALS_NAMES } from '@/constants';

import { SxClassProps } from '@/types';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';
import { ScreenName, AnalyticsEventName, CtaText, CtaLocation } from '@/types/enums';

const caregiverReviewsSliderSettings = {
  className: 'center',
  dots: true,
  arrows: false,
  infinite: true,
  speed: 200,
  slidesToShow: 3,
  touchThreshold: 150,

  centerMode: true,
  centerPadding: '100px',

  responsive: [
    // These options are not mobile first, so in this case the setting are going to apply for
    // breakpoints < 1280px (lg)
    {
      breakpoint: 1279,
      settings: {
        centerMode: false,
        infinite: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
  ],
};

const classes: SxClassProps = {
  wrapper: {
    maxWidth: {
      xs: '500px',
      sm: 'none',
    },
    boxSizing: 'content-box',
    margin: '0 auto',
    display: 'flex',
    gap: {
      xs: 0,
      sm: 4,
      xl: 9,
    },
    alignItems: 'center',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    justifyContent: 'center',
    paddingBottom: {
      xs: 7,
      md: 12,
    },
    paddingX: {
      xs: 3,
      sm: 4,
      lg: 10,
    },
  },

  header: (theme) => ({
    marginBottom: 2,
    lineHeight: {
      xs: '42px',
      lg: '34px',
    },

    minWidth: '237px',
    maxWidth: '300px',
    '& strong': {
      boxDecorationBreak: 'clone',
      backgroundColor: '#E7F0FB',
      padding: {
        xs: '2px',
        sm: '4px',
        md: '4px',
      },
      borderRadius: '8px',
      lineHeight: {
        sm: '42px',
        md: '60px',
      },
    },

    fontSize: '28px',

    [theme.breakpoints.up(470)]: {
      maxWidth: 'initial',
    },
    [theme.breakpoints.between(834, 'md')]: {
      minWidth: '420px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 'initial',
      fontSize: '36px',
    },
  }),
  subheader: {
    fontSize: {
      xs: '18px',
      sm: '21px',
    },
    lineHeight: '26px',
    maxWidth: {
      xl: '544px',
    },
    paddingRight: {
      xs: 0,
      md: '21%',
      lg: 0,
    },
  },
  features: {
    marginTop: {
      xs: 3,
      sm: 4,
    },
    marginBottom: {
      xs: 5,
      sm: 3,
    },
    maxWidth: {
      xl: '473px',
    },
    paddingRight: {
      xs: 0,
      md: '21%',
      lg: 0,
    },
  },
  featureText: {
    fontSize: {
      xs: '16px',
      sm: '18px',
    },
  },
  iconWrapper: {
    flexShrink: 0,
    marginRight: {
      xs: 2,
      sm: 4,
    },
    width: {
      xs: '43px',
      lg: '64px',
    },
    height: {
      xs: '43px',
      lg: '64px',
    },
    '& svg': {
      width: '100%',
    },
  },
  heartIconWrapper: {
    flexShrink: 0,
    marginRight: {
      xs: '24px',
      sm: '36px',
      md: '39px',
    },
    width: {
      xs: '41px',
      lg: '60px',
    },
    height: {
      xs: '41px',
      lg: '60px',
    },
    '& svg': {
      width: '100%',
    },
  },
  testimonialsCarouselWrapper: {
    flexShrink: 0,
    width: {
      xs: '340px',
      sm: '311px',
      lg: '637px',
    },
    height: {
      xs: '400px',
      sm: '421px',
      lg: '455px',
    },
    overflow: 'hidden',
    position: 'relative',
  },
  previousButton: {
    width: '100px',
    height: '400px',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 5,
    cursor: 'pointer',
    display: {
      xs: 'none',
      lg: 'block',
    },
    background:
      'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%)',
  },
  nextButton: {
    width: '125px',
    height: '400px',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 5,
    cursor: 'pointer',
    display: {
      xs: 'none',
      lg: 'block',
    },
    background:
      'linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%)',
  },
  ctaButton: {
    marginTop: {
      xs: 4,
      sm: 0,
    },
    width: {
      xs: '100%',
      sm: '184px',
    },
  },
};

interface CaregiverReviewsProps extends WithLazyLoadProps {
  setSelectedVertical: Function;
  setOpenModalOrDrawer: Dispatch<SetStateAction<boolean>>;
}

const CTA_TEXT = 'Get started';

function CaregiverReviews({
  hasLoaded,
  setSelectedVertical,
  setOpenModalOrDrawer,
}: CaregiverReviewsProps) {
  const { isMobile } = useResponsive();
  const [carouselRef, setCarouselRef] = useState<Slider | null>();
  const setCarouselSliderRef = (slider: Slider | null) => {
    setCarouselRef(slider);
  };

  const handleCTAClick = () => {
    setSelectedVertical(UNKNOWN_VERTICAL);
    setOpenModalOrDrawer(true);

    AnalyticsHelper.logEvent({
      name: AnalyticsEventName.CTA_INTERACTED_VHP,
      data: {
        screen_name: ScreenName.VISITOR_HOMEPAGE,
        enrollment_initiated: true,
        cta_text: CtaText.GET_STARTED,
        cta_location: CtaLocation.VHP_BODY,
        vertical: VERTICALS_NAMES.unknown,
      },
    });
  };

  const moveSlide = (isNextDirection?: boolean) => {
    if (!carouselRef) {
      return;
    }
    if (isNextDirection) {
      carouselRef.slickNext();
    } else {
      carouselRef.slickPrev();
    }
  };

  return (
    <Box sx={classes.wrapper} data-testid="caregiverReviews">
      <Box>
        <Typography variant="h1" color="textPrimary" sx={classes.header}>
          Don&apos;t just rely on a <strong>single referral</strong>
        </Typography>

        <Typography variant="body1" color="textPrimary" sx={classes.subheader}>
          Tap into the one of the largest online services for finding and managing care.
        </Typography>
        <Box sx={classes.features}>
          <Box display="flex" alignItems="center" mb={4}>
            <Box sx={classes.iconWrapper}>
              <BackgroundCheckIcon />
            </Box>
            <Typography variant="body2" color="textPrimary" sx={classes.featureText}>
              100% of caregivers are background checked before you can hire
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={4}>
            <Box sx={classes.iconWrapper}>
              <PhotoIcon />
            </Box>
            <Typography variant="body2" color="textPrimary" sx={classes.featureText}>
              Transparent profiles, ratings and reviews
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={4}>
            <Box sx={classes.heartIconWrapper}>
              <MessageHeartIcon />
            </Box>
            <Typography variant="body2" color="textPrimary" sx={classes.featureText}>
              Dedicated safety and support teams
            </Typography>
          </Box>
        </Box>

        {!isMobile && (
          <Button
            color="secondary"
            variant="contained"
            size="large"
            sx={classes.ctaButton}
            onClick={handleCTAClick}>
            {CTA_TEXT}
          </Button>
        )}
      </Box>

      {hasLoaded && (
        <Box sx={classes.testimonialsCarouselWrapper}>
          <Box
            sx={classes.previousButton}
            onClick={() => {
              moveSlide();
            }}
          />

          <TestimonialsCarousel
            items={REBRANDING_TESTIMONIAL_ITEMS}
            sliderSettings={caregiverReviewsSliderSettings}
            setRef={setCarouselSliderRef}
          />
          <Box
            sx={classes.nextButton}
            onClick={() => {
              moveSlide(true);
            }}
          />
        </Box>
      )}

      {isMobile && (
        <Button
          color="secondary"
          variant="contained"
          size="large"
          sx={classes.ctaButton}
          onClick={handleCTAClick}>
          {CTA_TEXT}
        </Button>
      )}
    </Box>
  );
}

export default CaregiverReviews;
